import './App.css';
import React, {useState, useEffect} from 'react';
import 'bootstrap/dist/css/bootstrap.min.css';
import AppNavigator from './router';

function App() {

  // global.url = "http://162.55.212.193:82/";
  //  test new
  //global.url = "http://162.55.212.193:888/";

  //global.url = "http://demo.hms-edassery.com/";

  global.url = "http://api.hms-edassery.com/";

  // live
  // global.url = "http://162.55.212.193:89/";
 

  return (
      <AppNavigator />
  );
}

export default App;
